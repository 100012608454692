<template>
  <div></div>
</template>

<script>
import introJs from 'intro.js'

export default {
  name: 'Onboarding',
  data() {
    return {
      onboarding: null
    }
  },
  mounted() {
    const intro = introJs('#nfe')

    intro
      .setOption('tooltipClass', 'onboarding')
      .setOption('dontShowAgain', true)
      .setOption('dontShowAgainLabel', 'Não mostrar novamente')
      .setOption('nextLabel', 'Próximo')
      .setOption('prevLabel', 'Anterior')
      .setOption('doneLabel', 'Finalizar')
      .setOptions({
        steps: [
          {
            title: 'Parabéns pela nova aplicação!',
            intro:
              'A aplicação de notas fiscais irá facilitar a forma de gerar suas notas e administrá-las. Vamos conhecê-la?'
          },
          {
            title: 'Cadastro',
            element: document.querySelector(
              '.tab-container-keys li:last-child'
            ),
            intro:
              'Antes de tudo será preciso cadastrar um CNPJ válido. Então comece por aqui!'
          },
          {
            title: 'Nova nota fiscal',
            element: document.querySelector('.issue-nf__button'),
            intro:
              'Depois, ao clicar nesse botão, um modal abrirá para o preenchimento da emissão de sua nota'
          },
          {
            title: 'Gerenciamento de notas',
            element: document.querySelector(
              '.tab-container-keys li:nth-child(2)'
            ),
            intro:
              'Aqui você será capaz de reenviar, duplicar e cancelar o envio de uma nota. Além de visualizar todas notas já emitidas'
          },
          {
            title: 'Dashboard',
            element: document.querySelector(
              '.tab-container-keys li:first-child'
            ),
            intro:
              'Essa área irá conter o resumo do mês em uma visualização rápida e resumida da situação de sua conta e de suas emissões'
          }
        ]
      })

    intro.start()
  }
}
</script>
